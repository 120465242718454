$(document).ready(function(){
  <!-- Rating@Mail.ru counter -->

  var _tmr = window._tmr || (window._tmr = []);
  _tmr.push({id: "2847572", type: "pageView", start: (new Date()).getTime()});
  (function (d, w, id) {
  if (d.getElementById(id)) return;
  var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
  ts.src = (d.location.protocol == "https:" ? "https:" : "http:") + "//top-fwz1.mail.ru/js/code.js";
  var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
  if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
  })(document, window, "topmailru-code");

  <!-- //Rating@Mail.ru counter -->

  $('.fancybox').fancybox();

  var header       = $('#header'),
      main         = $('#main'),
      headerHeight = header.height();
      smallHeight  = 82;

  $(this).on('scroll', function(e){
    var scrollTop = $(this).scrollTop();
    if(scrollTop < headerHeight - smallHeight){
      main.removeClass('fixed');
      main.css({'marginTop':0 + 'px'});
    } else {
      main.addClass('fixed');
      main.css({'marginTop':headerHeight + 'px'});
    }
  });
  $(this).scroll();

  var footer = $('.footer'),
      push   = $('.push');
  $(window).resize(function(){
    var footerHeight    = footer.outerHeight();
    var stickFooterPush = push.height(footerHeight);
    $('.main').css({'marginBottom':'-' + footerHeight + 'px'});
  });
  $(window).resize();


  // Added height for carousel
  addHeightCarousel();
  $(window).resize(function(){
    addHeightCarousel();
  });

  //intercept filter click
  $('.custom-select').on('click', function(){
      var select      = $(this).find('#filter-select');;
      var filterUrl = $(select).val();
      window.location.href= filterUrl;
  });

});


var maxHeight   = 365;
var minHeight   = 60;
var mobileWidth = 768;
function addHeightCarousel() {

  var windowWight = $(window).width();
  var element     = $('#mainCarousel');
  var heightElement = $(element).data('height');

  if(windowWight > mobileWidth ) {
    $(element).find('.item').each(function(){
      var newHeign = maxHeight * (heightElement/100);
        $(this).css({height: newHeign});
    });
  } else {
    $(element).find('.item').each(function(){
        var newHeign = minHeight * (heightElement/100);
        $(this).css({height: newHeign+'vw'});
    });
  }
}
