$(document).ready(function(){
  var $burger = $("#burger"),
      $navItems =$(".header-nav-items"),
      $navItem =$(".header-nav-item"),
      $close = $('#header-nav-close');
  $burger.click(function(e){
    e.preventDefault();
    $(this).hide();
    $navItem.fadeIn().css("display","block");
    $navItems.css("height", "auto");
    $close.show();
  });

  $close.click(function(e) {
    e.preventDefault();
    $burger.css("display", "block");
    $navItem.hide();
    $navItems.css("height", "40px");
    $(this).hide();
  })
});
