$(document).ready(function(){
  var $commentAnswer = $('.comment-answer');
  $commentAnswer.click(function(e){
    e.preventDefault();
    $parent = $(this).closest('.comment-footer')
    $parent.find('.comments-subform').toggle();
  });

  var $commentTextInput = $('.comment-text-input');
  $commentTextInput.keypress(function(e){
    if(e.which == 13) {
      e.preventDefault();
    }
  });
})

/*
$(document).ready(function(){
    var _tmr = window._tmr || (window._tmr = []);
    _tmr.push({id: "2847572", type: "pageView", start: (new Date()).getTime()});
    (function (d, w, id) {
      if (d.getElementById(id)) return;
      var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
      ts.src = (d.location.protocol == "https:" ? "https:" : "http:") + "//top-fwz1.mail.ru/js/code.js";
      var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
      if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
    })(document, window, "topmailru-code");
})
*/
